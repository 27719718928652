import Swiper from 'swiper/bundle';

$(document).on('turbolinks:load', function () {
  var top = $('.top-slide');
  if (top.get(0)) {
    var mySwiper = new Swiper('.top-slide', {
      width: 1000,
      loop: true,
      slidesPerView: 1.8,
      spaceBetween: 10,
      centeredSlides: true,
      initialSlide: 1,
      pagination: {
        el: '.swiper-pagination',
      },
      autoplay: {
        delay: 8500,
        disableOnInteraction: true
      },
    });
  }
});