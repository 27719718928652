$(document).on('turbolinks:load', function () {
  var agree_ckb = $('#mebership_agreement');
  if (agree_ckb.get(0)) {
    agree_ckb.on('change', function () {
      var agree_btn = $('#mebership_agreement_btn');
      if (agree_ckb.is(':checked')) {
        agree_btn.removeClass('disabled')
      } else {
        agree_btn.addClass('disabled')
      }
    });
  }
  var company_ckb = $('#member_company_chk');
  var company_text = $('#member_company');
  if (company_ckb.get(0)) {
    company_ckb.on('change', function () {
      if (company_ckb.is(':checked')) {
        company_text.prop("disabled", false);
      } else {
        company_text.prop("disabled", true);
      }
    });
    if (company_ckb.is(':checked')) {
      company_text.prop("disabled", false);
    } else {
      company_text.prop("disabled", true);
    }
  }
});