$(document).on('turbolinks:load', function () {
  var search = function () {
    var wd = $('#item_search_keyword').val();
    $(".item").each(function (index, element) {
      var elm = $(element);
      var word = elm.data('word');
      if (word.indexOf(wd) != -1) {
        elm.fadeIn();
      } else {
        elm.fadeOut();
      }
    });
  };
  $('#btn_item_search').click(function () {
    search();
  });
  $('#item_search_keyword').keypress(function () {
    search();
  });
  $('#item_order').change(function () {
    var items = $(".item");
    var container = $('#item_container');

    items.sort(function (a, b) {
      var order_by = $('#item_order').val().split(":"),
        field = order_by[0],
        type = order_by[1],
        val_a = parseInt($(a).data(field)),
        val_b = parseInt($(b).data(field));

      if (type === 'asc') {
        return (val_a < val_b ? -1 : 1);
      } else if (type === 'desc') {
        return (val_a < val_b ? 1 : -1);
      }
    });
    container.html("");
    items.each(function () {
      container.append($(this));
    });
  });
  $('#rakuten_renkei_button').click(function () {
    $('#rakuten_renkei_form').submit();
  });
});
