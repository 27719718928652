import 'bootstrap';
import 'moment';
import 'moment/locale/ja';
import './login';
import './top';
import './item';
import './order';

import './membership';

import './search_area';


