// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("swiper")
require("easy-autocomplete")

// JavaScript
import '../javascripts/tempusdominus-bootstrap-4';
import '../javascripts/application';

//import '../javascripts/bootstrap-fileinput/locales/ja';

// CSS
import '../stylesheets/tempusdominus-bootstrap-4.css';
import '../stylesheets/application.scss';

// import '../stylesheets/light-bootstrap-dashboard/css/light-bootstrap-dashboard.css';

// import '../stylesheets/bootstrap-fileinput/scss/fileinput';
//import '../stylesheets/bootstrap-fileinput/css/glyphicon-icon';

require.context('../images', true)

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.$ = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
