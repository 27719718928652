var search_area_load = function () {

  $input = $('*[data-behavior="autocomplete"]')
  var options = {
    url: function (phrase) {
      return "/areas/autocomplete?q=" + phrase;
    },
    getValue: "name",
    list: {
      maxNumberOfElements: 15,
      match: {
        enabled: true
      }
    }
  };
  $input.easyAutocomplete(options);

  // 日本語変換に対応
  $input.on('compositionend', function () {
    let evt = jQuery.Event("keyup");
    evt.keyCode = 41;
    $input.trigger(evt);
  });

  // エンターキーでクリックイベントを発火
  $("#search_area_zipcode").keydown(function () {
    if (event.keyCode == 13) {
      $("#btn_search_area_zipcode").click();
    }
  });

  // クリック時処理
  $('#btn_search_area_zipcode').click(function () {
    var input_text = $('#search_area_zipcode').val();
    if (!input_text) {
      return;
    }
    // 郵便番号の入力の場合に動作
    var zip_code_pattern = new RegExp(/^[0-9]{3}-?[0-9]{4}$/);
    if (zip_code_pattern.test(input_text)) {
      $.ajax({
        url: "https://maps.googleapis.com/maps/api/geocode/json",
        type: "GET",
        dataType: "json",
        data: {
          key: google_api_key,
          address: input_text,
          language: "ja"
        }
      }).done(function (data) {
        console.log(data);
        if (data.status == "OK") {
          let size = data.results[0].address_components.length
          if (size > 3) {
            $('#area_name').val(data.results[0].address_components[size - 3].long_name);
            $('#sub_area_name').val(data.results[0].address_components[size - 4].long_name);
            $('#search_store_form').submit();
          } else {
            alert("郵便番号" + input_text + "に該当する店舗は見つかりませんでした。");
          }
        } else {
          alert("郵便番号" + input_text + "に該当する店舗は見つかりませんでした。");
        }
      });
    } else {
      if (/[0-9\-]+/.test(input_text)) {
        alert("郵便番号は7桁の数字で入力してください。");
        return;
      }
      // XX区〇〇 という入力に対して処理をする。
      var index = input_text.indexOf("区");
      if (0 < index && index + 1 < input_text.length) {
        $('#area_name').val(input_text.substring(0, index + 1));
        $('#sub_area_name').val(input_text.substring(index + 1));
        $('#search_store_form').submit();
      } else {
        alert("入力された住所に該当する配達エリアが見つかりませんでした。");
      }
    }
  });

  if (navigator.geolocation) {
    $('#btn_modal_ok').click(function () {
      $('#search_store_form').submit();
    });

    $("#search_area_location").prop("disabled", false);
    $('#search_area_location').click(function () {
      $('#btn_modal_ok').prop("disabled", true);
      $('#search_area_searching').show();
      $('#search_area_search-end').hide();
      $('#search_area_search-error').hide();
      $('#search_area_modal').modal('show');

      const option = {
        enableHighAccuracy: true, // Boolean値を指定します。trueを指定すると、より精度の高い情報を取得するように試みます。
        timeout: 8000,
        maximumAge: 0,
      };

      var on_error = function (error) {
        var err_msg = "位置情報が取得できませんでした。";
        switch (error.code) {
          case 1:
            err_msg = "位置情報の利用が許可されていません";
            break;
          case 2:
            err_msg = "デバイスの位置が判定できません";
            break;
          case 3:
            err_msg = "タイムアウトしました";
            break;
        }
        $('#search_area_searching').hide();
        $('#search_area_search-end').hide();
        $('#search_area_search-error').show();
        $('#search_area_error').html(err_msg);
      };
      var get_address = function (address_components) {
        var comps = address_components;
        var len = comps.length;
        var address1 = "";
        var address2 = "";
        var address3 = "";
        for (let i = 0; i < len; i++) {
          comp = comps[i]
          if (comp.types.indexOf("administrative_area_level_1") >= 0) {
            address1 = comp.long_name
          } else if (comp.types.indexOf("locality") >= 0) {
            address2 = comp.long_name
          } else if (comp.types.indexOf("sublocality_level_2") >= 0) {
            address3 = comp.long_name
          }
        }
        return [address1, address2, address3];
      };

      navigator.geolocation.getCurrentPosition(
        function (pos) {
          var lat = pos.coords.latitude;
          var lon = pos.coords.longitude;
          if (!lat || !lon) {
            on_error();
            return;
          }
          var gmapGeocoder = new google.maps.Geocoder();
          gmapGeocoder.geocode({ location: new google.maps.LatLng(lat, lon) }, function (results, status) {
            console.log(results);
            if (status == google.maps.GeocoderStatus.OK && results[0].geometry) {
              $('#search_area_searching').hide();
              $('#search_area_search-end').show();
              $('#search_area_search-error').hide();
              var address_components = get_address(results[0].address_components);
              $('#search_area_address').html(address_components.join(''));
              // 送信用Hidden要素
              $('#area_name').val(address_components[1]);
              $('#sub_area_name').val(address_components[2]);
              $('#btn_modal_ok').prop("disabled", false);
            } else {
              alert("住所取得失敗");
            }
          })
        },
        on_error, option);
    });
  } else {
    $("#search_area_location").prop("disabled", true);
  }
};
window.search_area_load = search_area_load;
$(document).on('turbolinks:load', search_area_load);